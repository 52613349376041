/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

#main-content {
  background-color: white;
}
// Estilos globales
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100vh;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

// Customize css
.mat-drawer {
  background-color: white;
}

.mat-toolbar {
  color: white;
}

.mat-list-base a.mat-list-item {
  color: var(--ion-color-primary);
}

.dialog-responsive {
  width: 40%;
}

@media only screen and (max-width: 760px) {
  .dialog-responsive {
      width: 100%;
  }
}

.myPanelClass{
  margin-top: 30px !important;
}
